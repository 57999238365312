import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [~13],
		"/about": [14],
		"/blog": [15],
		"/blog/[slug=slug]": [16],
		"/community": [17],
		"/dao": [18],
		"/diagnostics": [~19],
		"/glossary": [20,[2]],
		"/glossary/[slug=slug]": [21,[2]],
		"/newsletter": [22],
		"/qr": [23],
		"/search": [24],
		"/sentry-test": [~25],
		"/slow-load": [26],
		"/strategies": [~27,[3]],
		"/strategies/[strategy]": [28,[3,4]],
		"/strategies/[strategy]/backtest": [48,[3,4]],
		"/strategies/[strategy]/(wizard)/connect-wallet/balance": [29,[3,4,5,6]],
		"/strategies/[strategy]/(wizard)/connect-wallet/connect": [30,[3,4,5,6]],
		"/strategies/[strategy]/(wizard)/connect-wallet/introduction": [31,[3,4,5,6]],
		"/strategies/[strategy]/(wizard)/connect-wallet/success": [32,[3,4,5,6]],
		"/strategies/[strategy]/(wizard)/deposit/balance": [33,[3,4,5,7]],
		"/strategies/[strategy]/(wizard)/deposit/connect": [34,[3,4,5,7]],
		"/strategies/[strategy]/(wizard)/deposit/introduction": [35,[3,4,5,7]],
		"/strategies/[strategy]/(wizard)/deposit/payment": [36,[3,4,5,7]],
		"/strategies/[strategy]/(wizard)/deposit/success": [37,[3,4,5,7]],
		"/strategies/[strategy]/(wizard)/deposit/tos": [38,[3,4,5,7]],
		"/strategies/[strategy]/description": [49,[3,4]],
		"/strategies/[strategy]/(wizard)/error": [39,[3,4,5]],
		"/strategies/[strategy]/fees": [50,[3,4]],
		"/strategies/[strategy]/netflow": [51,[3,4]],
		"/strategies/[strategy]/performance": [52,[3,4]],
		"/strategies/[strategy]/(wizard)/redeem/connect": [40,[3,4,5,8]],
		"/strategies/[strategy]/(wizard)/redeem/deposit-status": [41,[3,4,5,8]],
		"/strategies/[strategy]/(wizard)/redeem/introduction": [42,[3,4,5,8]],
		"/strategies/[strategy]/(wizard)/redeem/shares-redemption": [43,[3,4,5,8]],
		"/strategies/[strategy]/(wizard)/redeem/success": [44,[3,4,5,8]],
		"/strategies/[strategy]/source": [53,[3,4]],
		"/strategies/[strategy]/tech-details": [54,[3,4,11]],
		"/strategies/[strategy]/tech-details/decision-making": [55,[3,4,11]],
		"/strategies/[strategy]/tech-details/logs": [56,[3,4,11]],
		"/strategies/[strategy]/tech-details/status": [57,[3,4,11]],
		"/strategies/[strategy]/vault": [58,[3,4]],
		"/strategies/[strategy]/[status=positionStatus]-positions": [45,[3,4,9]],
		"/strategies/[strategy]/[status=positionStatus]-positions/[position=integer]": [46,[3,4,9,10]],
		"/strategies/[strategy]/[status=positionStatus]-positions/[position=integer]/trade-[trade=integer]": [47,[3,4,9,10]],
		"/trading-view": [59],
		"/trading-view/api": [72],
		"/trading-view/backtesting": [73],
		"/trading-view/blockchains": [74],
		"/trading-view/exchanges": [75],
		"/trading-view/lending-reserves": [76],
		"/trading-view/top-list": [77],
		"/trading-view/top-list/[direction=momentum]": [78],
		"/trading-view/trading-pairs": [79],
		"/trading-view/[chain=slug]": [60,[12]],
		"/trading-view/[chain=slug]/exchanges": [65,[12]],
		"/trading-view/[chain=slug]/lending": [66,[12]],
		"/trading-view/[chain=slug]/lending/[protocol]": [67,[12]],
		"/trading-view/[chain=slug]/lending/[protocol]/[reserve]": [68,[12]],
		"/trading-view/[chain=slug]/tokens": [69,[12]],
		"/trading-view/[chain=slug]/tokens/[token]": [70,[12]],
		"/trading-view/[chain=slug]/trading-pairs": [71,[12]],
		"/trading-view/[chain=slug]/[exchange]": [61,[12]],
		"/trading-view/[chain=slug]/[exchange]/export-data": [64,[12]],
		"/trading-view/[chain=slug]/[exchange]/[pair]": [62,[12]],
		"/trading-view/[chain=slug]/[exchange]/[pair]/api-and-historical-data": [63,[12]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';